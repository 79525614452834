// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../components/common/main-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import design_icon from "../images/services/icons/design_icon_green.svg"
import develop_icon from "../images/services/icons/development.svg"
import logistic_icon from "../images/services/icons/logistics_green.svg"
import manufac_icon from "../images/services/icons/manufacturing_green.svg"
import rapid_icon from "../images/services/icons/rapic_prototyping.svg"
import sourcing_icon from "../images/services/icons/sourcing_green.svg"

//import white icons

import design_white_icon from "../images/services/icons/design_icon_white.svg"
import develop_white_icon from "../images/services/icons/development_white.svg"
import logistic_white_icon from "../images/services/icons/logistics_white.svg"
import manufac_white_icon from "../images/services/icons/manufacturing_white.svg"
import rapid_white_icon from "../images/services/icons/rapic_prototyping_WHite.svg"
import soutcing_white_icon from "../images/services/icons/sourcing_white.svg"
import Concept_Delivery from "../components/common/concept_slider"

const Services = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    Softmatter_Lab: convertToBgImage(getImage(data.Softmatter_Lab)),
    Advanced_Engineering: convertToBgImage(getImage(data.Advanced_Engineering)),
    text_image: convertToBgImage(getImage(data.text_image)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    slider_1: getImage(data.slider_1),
    slider_2: getImage(data.slider_2),

    //home images

    advanced_manufac_img: convertToBgImage(getImage(data.home1)),
    asset1_img: convertToBgImage(getImage(data.home2)),
    asset18_img: convertToBgImage(getImage(data.home3)),
    design_n_develop_img: convertToBgImage(getImage(data.home4)),
    material_img: convertToBgImage(getImage(data.home5)),
    npi_img: convertToBgImage(getImage(data.home6)),
    supply_chain_img: convertToBgImage(getImage(data.home7)),
  }

  const [activePlatform, setActivePlatform] = useState(0)

  const concept_delivery = [
    {
      icon_green: design_icon,
      icon_white: design_white_icon,
      title: "Design",
      content:
        "Our ability to turn design into a next-to-skin concept is unapparelled. We have expanded our Industrial Design capabilities, apparel ID, and knit-to-shape programming",
    },
    {
      icon_green: develop_icon,
      icon_white: develop_white_icon,
      title: "Product Development",
      content:
        "Transforming designs into prototypes via Electronic, Design and Product validation.",
    },
    {
      icon_green: rapid_icon,
      icon_white: rapid_white_icon,
      title: "Rapid Prototyping",
      content:
        "Safely proofing your product through functional and wash testing, for elevated performance.",
    },
    {
      icon_green: sourcing_icon,
      icon_white: soutcing_white_icon,
      title: "Raw Material Sourcing",
      content:
        "Our wide ecosystem drives easy access to reliable suppliers that provide the right parts of the puzzle. Expansion with an East-Asia sourcing hub for non-core material. ",
    },
    {
      icon_green: manufac_icon,
      icon_white: manufac_white_icon,
      title: "Scaled Manufacturing",
      content:
        "Our large production facilities, located across the globe, allows us to scale-up at your discretion just like that. Expansion of custom tooling and jig development for automated product validation, outgoing QC, serialization, and traceability ",
    },
    {
      icon_green: logistic_icon,
      icon_white: logistic_white_icon,
      title: "Logistics",
      content:
        "Delivering our customers' products to the market in adherence to specific smart product protocol. ",
    },
  ]

  const slider = [
    {
      title: "Research Partner",
      description:
        "Loughborough University is a public research university, renowned for the excellence and relevance of research; their results help shape public policy, improve lives, and enable business and industry to compete more effectively.",
      content: "University of Loughborough",
      image: images.slider_2,
    },
    {
      title: "Industrial Design Partner",
      description:
        "Priority Design is a global leader for Wearable technology Product Design. The company offers design and integrated services to more than 70 countries globally.",
      content: "Priority Design",
      image: images.slider_1,
    },
  ]

  const [active, setActive] = useState(0)
  const [active2, setActive2] = useState(0)

  const platform = [
    {
      title: "Design and Development",
      sub_title: "",
      content:
        "Transforming designs into prototypes via electronic, design and product validation. That’s why an experienced partner can support you with your idea - safely and quickly. We will focus on diligent scoping and upfront risk detection and help you bridge the gap between a vision and a scaled product.",
      image: "",
      cover: images.design_n_develop_img,
    },
    {
      title: "Materials",
      sub_title: "",
      content:
        "Designing new products for manufacturing requires the expertise in understanding how materials perform, that they are within regulatory standards, and is the right fit to avoid costly setbacks and delays. Our MAS vertical supply chain and external eco-systems and libraries are extensive and help you stay on track to meet your milestones safely and quickly. ",
      image: "",
      cover: images.material_img,
    },
    {
      title: "Advanced Engineering and Testing",
      sub_title: "",
      content:
        "We have developed know-how processes to integrate material, electronics, and stretchable substrates  ",
      image: "",
      cover: images.Advanced_Engineering,
    },
  ]

  const platform2 = [
    {
      title: "NPI",
      sub_title: "",
      content:
        "We take the best of what we have learned in our decades of manufacturing experience to make sure we can scale. There are a lot of factors to consider – manufacturability, cost, reliability, and more. We offer the guidance and know-how to make sure your product can be built cost-effectively and at scale.",
      image: "",
      cover: images.npi_img,
    },
    {
      title: "Advanced Manufacturing",
      sub_title: "",
      content:
        "We offer guidance to ensure your product can be built cost-effectively and at scale. We perform product validation and value analysis/engineering.",
      image: "",
      cover: images.advanced_manufac_img,
    },
    {
      title: "Supply Chain",
      sub_title: "",
      content:
        "Are you looking for a partner who can help you build a more resilient supply chain?  Manage part fulfillment and logistics? Our solutions keep tabs on what goes where and when. We help you reduce waste and overheads. We will efficiently manage these operations, so you can speed your time-to-market and dependably to meet your customers’ needs.",
      image: "",
      cover: images.supply_chain_img,
    },
  ]

  return (
    <Layout>
      <Seo title="Services" />
      <section>
        <BackgroundImage
          {...images.dusk_texure}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white max-w-xl ">
                    Sketch-to-shelf partner
                  </div>
                  <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Your end-to-end solution provider
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className=" absolute right-0 bottom-0">
                  <StaticImage
                    src="../images/services/hero.png"
                    alt="Softmatter"
                    placeholder="blurred"
                    width={820}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-mint mb-4 uppercase text-center ">
                    COLLABORATE WITH US
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Partnership for Success
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    Innovate with our proven concept development process. Our
                    commitment to
                    <div>
                      sustainability and engineered craftsmanship means that we
                      are thoughtful in
                    </div>
                    both design and function.
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk">
          <div className="container mx-auto py-20">
            <Fade bottom>
              <div className="text-mint mb-4 uppercase text-center ">
                TAILORED SERVICES FOR UNIQUE PRODUCTS
              </div>
              <div className="text-5xl lg:text-6xl text-center font-light mb-4 text-white">
                Concept to Delivery
              </div>
              <div className="text-2xl font-light text-center max-w-5xl mx-auto leading-snug mt-4 text-white">
                With our proprietary ideation and development process, you are
                rest assured that we have thought of nearly everything and are
                able to offer you in-house solutions for prototyping, product
                design scaled manufacturing and deployment across markets around
                the world.
              </div>
            </Fade>
            <Fade bottom cascade>
              <Concept_Delivery concept__delivery={concept_delivery} />
            </Fade>
          </div>
        </div>
      </section>
      <section>
        <div className="">
          <div className="flex lg:flex-row flex-col bg-chalk">
            <div className="w-full lg:w-1/2 lg:py-0 py-20 px-5 sm:text-left text-center sm:pl-24 lg:pl-36 flex flex-col justify-center">
              <Fade>
                <div className="text-dusk sm:pr-36">
                  <div className="text-4xl font-light mb-3 leading-tight">
                    Softmatter Labs
                  </div>
                  <div className="text-xl max-w-xl">
                    We are pioneers of engineered soft goods that integrate
                    textile, structure, and embedded electronics.
                  </div>
                </div>
              </Fade>
            </div>
            <div className="w-full lg:w-1/2">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Softmatter_Lab}
                    className="py-52 sm:py-72 "
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            {/* <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Application
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                A breakthrough foam and padding technology for impact protection
                and the encasing of electronics.
              </div>
            </Fade> */}
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-10 sm:gap-5">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            active == index
                              ? "font-firs-semibold cursor-pointer text-mint sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-5 border-mint duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-5 border-transparent duration-200"
                          }
                          onClick={() => setActive(index)}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform[active].cover}
                      className="w-full  lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="">
          <div className="flex lg:flex-row flex-col overflow-hidden bg-chalk">
            <div className="lg:w-1/2 w-full">
              <Fade lrft>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.text_image}
                    className="py-52 lg:py-72 "
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2 w-full px-5 lg:py-0 pt-20 lg:text-left text-center lg:px-36 flex flex-col justify-center">
              <Fade>
                <div className="text-dusk lg:pr-10">
                  <div className="text-4xl font-light mb-3 leading-tight">
                    We are the world’s largest
                    <div>smart apparel manufacturer</div>
                  </div>
                  <div className="text-xl lg:max-w-xl">
                    From NPI to scale manufacturing – we’ll help you take your
                    product to market as we build high-mix products from the
                    lowest volumes to full scaled manufacturing. With experience
                    in design for manufacturing (DFM) and DFX means we navigate
                    the complexities of new product launch.
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="py-20 bg-chalk">
          <div className="container mx-auto">
            <div className="">
              <div className="flex items-center justify-center lg:gap-20 sm:gap-5">
                {platform2.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div className="">
                        <div
                          className={
                            active2 == index
                              ? "font-firs-semibold cursor-pointer text-mint sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-5 border-mint duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-5 border-transparent duration-200"
                          }
                          onClick={() => setActive2(index)}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform2[active2].cover}
                      className="w-full lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-8 sm:p-14 flex flex-col justify-center">
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform2[active2].content}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-chalk  overflow-hidden">
          <div className="flex lg:flex-row flex-col">
            <div className="w-full lg:hidden pb-20 block">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.asset18_img}
                    className="py-52 sm:py-72 "
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2 w-full px-5 lg:py-0 p-0 lg:text-left text-center lg:px-36 flex flex-col justify-center">
              <Fade>
                <div className="text-dusk lg:pr-10">
                  <div className="text-4xl font-light mb-3 leading-tight">
                    Our Commitment
                    <div>to Quality</div>
                  </div>
                  <div className="text-xl lg:max-w-xl">
                    We create life-changing products to meet and exceed customer
                    expectations. We strive for the highest quality possible in
                    our innovations within our emerging industry and are
                    committed to continuous improvement. Softmatter operates
                    within a global quality management system adhering to the
                    requirements of ISO 9001, 14001 and 13485, OHSAS 18001, and
                    LEED compliance.
                  </div>
                </div>
              </Fade>
            </div>
            <div className="w-1/2 lg:block hidden">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.asset18_img}
                    className="py-72 "
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="flex lg:flex-row flex-col lg:py-0 pb-64 mb-96 ">
              <div className="w-full lg:w-4/12 sm:text-left text-center">
                <Fade bottom cascade>
                  <div className=" text-mint mb-20">
                    <Fade top>
                      <div className="uppercase text-xl font-light mb-4">
                        PARTNERS
                      </div>
                    </Fade>
                    <div className="text-4xl font-light mb-4 sm:max-w-xs">
                      Partners in Innovation
                    </div>
                    <div className="text-xl sm:max-w-sm">
                      Our partners support our mission to advance human
                      potential.
                    </div>
                    <Link to="/contact">
                      <button className="uppercase px-8 py-4 border bg-mint text-dusk hover:text-white rounded-full border-mint mt-8 hover:bg-mint/20 cursor-pointer duration-200">
                        Patners with us
                      </button>
                    </Link>
                  </div>
                </Fade>
              </div>
              <div className="w-full lg:w-7/12 pb-24">
                <div className="text-white absolute w-full">
                  <MainSlider slider_array={slider} service />
                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96 justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-5xl sm:text-6xl mb-4">
                  How to Engage
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-mint  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ServicesImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Softmatter_Lab: file(relativePath: { eq: "services/Softmatter_Lab.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Advanced_Engineering: file(
      relativePath: { eq: "services/Advanced_Engineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    text_image: file(relativePath: { eq: "services/text_image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "services/slider/slider_1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "services/slider/slider_2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    home1: file(relativePath: { eq: "services/Advaced_Manufacturing.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home2: file(relativePath: { eq: "services/Asset.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home3: file(relativePath: { eq: "services/Asset18.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home4: file(relativePath: { eq: "services/Design_and_Development.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home5: file(relativePath: { eq: "services/Material.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home6: file(relativePath: { eq: "services/NPI.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home7: file(relativePath: { eq: "services/supply_chain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Services
