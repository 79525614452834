import React from "react"
import Fade from "react-reveal/Fade"
// import Swiper core and required modules
import { Autoplay } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

const Concept_Delivery = ({ concept__delivery }) => {
  return (
    <>
      <div className="">
        <>
          <Fade bottom cascade>
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              slidesPerView={3}
              speed={1000}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 2,
                },

                0: {
                  slidesPerView: 1,
                },
              }}
              spaceBetween={15}
              loop={true}
              modules={[Autoplay]}
            >
              {concept__delivery.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="border group border-chalk hover:border-mint bg-chalk p-5 pb-10 hover:bg-mint text-dusk hover:text-white duration-200 mt-8">
                      <div className="py-2 flex sm:justify-start justify-center">
                        <img
                          src={item.icon_green}
                          alt="softmatter"
                          width={100}
                          className="block group-hover:hidden"
                        />
                        <img
                          src={item.icon_white}
                          alt="softmatter"
                          width={100}
                          className="group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light sm:text-left text-center font-firs-regular border-b border-mint group-hover:border-white  group pb-4 max-w-5xl leading-snug mt-6">
                        {item.title}
                      </div>
                      <div className="text-base font-light sm:text-left text-center max-w-5xl leading-snug mt-4 h-32 2xl:h-28">
                        {item.content}
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Fade>
        </>
      </div>
    </>
  )
}

export default Concept_Delivery
